import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import matexiLogo from 'assets/icons/matexi-logo-15-lecie.svg';

const Wrapper = styled.a`
  display: block;
`;

const Image = styled.img`
  ${({ size }) => {
    switch (size) {
      case 'sm':
        return css`
          width: 160px;
        `;
      case 'md':
        return css`
          width: 160px;
        `;
      case 'lg':
        return css`
          width: 190px;
        `;
      default:
        return css`
          width: 160px;
        `;
    }
  }}
`;

const Logo = ({ size, close }) => (
  <Wrapper
    href="https://matexipolska.pl/"
    target="_blank"
    rel="noopener noreferrer nofollow"
    onClick={() => close !== null && close()}
  >
    <Image src={matexiLogo} width="63" height="85" size={size} alt="logo" />
  </Wrapper>
);

Logo.propTypes = {
  close: PropTypes.func,
  size: PropTypes.string,
};

Logo.defaultProps = {
  close: null,
  size: 'md',
};

export default Logo;
