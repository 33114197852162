import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Icon from 'components/shared/Icon';
import Logo from 'components/shared/Logo';
import Content from 'components/shared/Content';
import menuIcon from 'assets/icons/menu.svg';
import Links from 'components/Navigation/Links';
import Sidenav from 'components/Navigation/Sidenav';
import useMedia from 'hooks/useMedia';
import sokratesaLogo from 'assets/images/sokratesa-logo.png';
import favouritesIcon from 'assets/icons/serce.svg';
import scrollToSection from 'utils/scrollToSection';

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => theme.navSize.mobile};
  z-index: 1000;
  transform: ${({ navHidden, theme }) =>
    navHidden ? `translate3d(0, -${theme.navSize.mobile}, 0)` : 'none'};
  transition: transform ease-in-out 250ms;
  ${({ theme }) => theme.mq.lg} {
    height: ${({ theme }) => theme.navSize.desktop};
    transform: ${({ navHidden, theme }) =>
      navHidden ? `translate3d(0, -${theme.navSize.desktop}, 0)` : 'none'};
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const LinksWrapper = styled.div`
  display: none;
  width: 100%;
  padding: 0 10px;
  ${({ theme }) => theme.mq.lg} {
    display: flex;
    justify-content: space-evenly;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  margin: 0 auto 0 10px;
  padding: 0 10px;
  height: ${({ theme }) => theme.navSize.mobile};
  ${({ theme }) => theme.mq.lg} {
    height: ${({ theme }) => theme.navSize.desktop};
    margin-right: 0;
  }
`;

const MenuButton = styled.button`
  display: block;
  background: transparent;
  cursor: pointer;
  margin-right: auto;
  ${({ theme }) => theme.mq.xxs} {
    margin-right: 0;
  }
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;

const WishlistButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 10px;
  cursor: pointer;
`;

const WishlistCount = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`;

const IconWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Navigation = () => {
  const [isNavHidden, setIsNavHidden] = useState(false);
  const [isSidenavVisible, setIsSidenavVisible] = useState(false);
  const [favsLength, setFavsLength] = useState(1);

  let prevScroll = 0;

  const handleScroll = e => {
    const window = e.currentTarget;

    if (prevScroll > window.scrollY) {
      setIsNavHidden(false);
    } else if (window.scrollY >= 100) {
      setIsNavHidden(true);
    }
    prevScroll = window.scrollY;
  };

  const getFavsLength = () => {
    const favFlats = JSON.parse(
      localStorage.getItem('sokratesa-11a-favourites'),
    );
    setFavsLength(favFlats ? favFlats.length : 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', e => handleScroll(e));

    return () => {
      window.removeEventListener('scroll', e => handleScroll(e));
    };
  }, []);

  useEffect(() => {
    getFavsLength();
    window.addEventListener('storage', getFavsLength);
  }, []);

  const hideNav = () => {
    if (window.scrollY >= 100) setIsNavHidden(true);
  };

  const closeSidenav = () => {
    setIsSidenavVisible(false);
    setTimeout(hideNav, 800);
    document.body.style.overflow = 'auto';
  };

  const openSidenav = () => {
    setIsSidenavVisible(true);
    document.body.style.overflow = 'hidden';
  };

  const matchesXXS = useMedia('xxs');
  const matchesLG = useMedia('lg');
  const matchesXXXL = useMedia('xxxl');

  const logoSize = (() => {
    if (matchesLG) {
      return 'lg';
    }
    return 'sm';
  })();

  return (
    <>
      {!matchesLG && (
        <Sidenav isVisible={isSidenavVisible} close={closeSidenav} />
      )}
      <Wrapper navHidden={isNavHidden}>
        <Content>
          <InnerWrapper>
            {!matchesLG && (
              <MenuButton onClick={openSidenav}>
                <Icon size={40} src={menuIcon} />
              </MenuButton>
            )}
            {matchesXXS && (
              <LogoWrapper>
                <Logo size={logoSize} />
              </LogoWrapper>
            )}
            {matchesLG && (
              <LinksWrapper>
                <Links close={() => setTimeout(hideNav, 800)} />
              </LinksWrapper>
            )}
            <IconWrapper href="/sokratesa-11a">
              <Icon
                size={matchesLG ? 65 : 40}
                src={sokratesaLogo}
                alt="sokratesa"
              />
            </IconWrapper>
            <WishlistButton onClick={() => scrollToSection('#browser')}>
              <Icon size={matchesLG ? 60 : 35} src={favouritesIcon} />
              <WishlistCount>{favsLength}</WishlistCount>
            </WishlistButton>
          </InnerWrapper>
        </Content>
      </Wrapper>
    </>
  );
};

export default Navigation;
